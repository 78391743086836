import React from "react"
import {graphql} from "gatsby";
import {Container} from "reactstrap";

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from '../components/pageTitle'
import {LangContext} from "../components/kbusWrapper";

const NotFoundPage = ({data}) => {
  return (
    <LangContext.Consumer>
      { context => (
        <Layout>
          <SEO title={context.translate(data, 'notfound', 'titulo')}/>
          <Container className="py-5 mb-5">
            <PageTitle title={context.translate(data, 'notfound', 'titulo')}/>
            <h6 className="text-center font-weight-bold pb-3 pt-4 text-danger">{context.translate(data, 'notfound', 'texto')}</h6>
          </Container>
        </Layout>
      )}
    </LangContext.Consumer>
  )
}

export default NotFoundPage

export const pageQuery = graphql`  
  query {
    notfound: strapi404 {
      titulo
      texto
    },
    notfound_eus: strapi404Eus {
      titulo
      texto
    }
  }
`